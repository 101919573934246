<template>
  <div class="flex justify-center my-5">
    <div>
      <nuxt-link to="/">
        <NuxtImg data-not-lazy :src="fileUrl(navigatieData.logo)" alt="Vers van de Velde" class="object-scale-down h-16"/>
      </nuxt-link>
    </div>

    <nav class="self-center ml-16 font-basic">
      <ul class="flex">
        <template v-for="(nav,i ) in navigatieData?.topbar">
          <li class="menu-item" v-if="nav.dropdown == undefined"  :key="i">
            <NuxtLink class="md:px-2 lg:px-6 hover:text-primary-400 auto-transit-3" :to="nav.url">{{ nav.naam }}</NuxtLink>
          </li>
          <li v-else class="relative px-4 tracking-wide cursor-pointer group dropdown menu-item-dropdown"  :key="i + 'a'">
            <NuxtLink :to="nav.url" class="hover:text-primary-400 auto-transit-3 whitespace-nowrap">{{ nav.naam }}</NuxtLink>
            <div class="absolute z-50 hidden h-auto group-hover:block dropdown-menu">
              <ul class="top-0 px-4 py-2 -ml-16 bg-white shadow w-60">
                <li class="py-1" v-for="(dropdown, id) in nav.dropdown" :key="id">
                  <NuxtLink :to="dropdown.url" class="block hover:text-primary-400 auto-transit-3">{{ dropdown.naam }}</NuxtLink>
                </li>
              </ul>
            </div>
          </li>
        </template>
        <li class="ml-6 mr-2">
          <nuxt-link to="/boodschappenlijst">
            <font-awesome-icon class="text-xl hover:text-primary-400 auto-transit-3" :icon="['fas', 'shopping-basket']"/>
            <span style="margin-top: -30px; margin-left: 15px" class="relative w-4 h-4 min-h-[1rem] min-w-[1rem] flex items-center justify-center rounded-full bg-primary-500 text-xs text-white" v-if="quantity > 0">{{quantity}}</span>
          </nuxt-link>
        </li>
        <li class="mx-2">
          <NuxtLink :to="user?.tempUser === false ? '/account' : '/inloggen'">
            <font-awesome-icon class="text-xl hover:text-primary-400 auto-transit-3" :icon="['fas','user']"/>
          </NuxtLink>
        </li>
        <li class="mx-2">
          <NuxtLink to="/inloggen" v-if="!user || user?.tempUser === true">
            <font-awesome-icon class="text-xl hover:text-primary-400 auto-transit-3" :icon="['fas','sign-in-alt']"/>
          </NuxtLink>
          <div v-else class="cursor-pointer" @click="uitloggen">
            <font-awesome-icon class="text-xl hover:text-primary-400 auto-transit-3" :icon="['fas','sign-out-alt']"/>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup>
import Swal from "sweetalert2";

import { useCart } from "~/store/useCart";
import { storeToRefs } from "pinia";

const cartStore = useCart();
const { cart, quantity } = storeToRefs(cartStore);
  
const router = useRouter();
const { getItems } = useDirectusItems();
const { logout } = useDirectusAuth();
// const { refreshTokens } = useDirectusToken();
const user = useDirectusUser();

if(!user?.value){
  try {
    // refreshTokens();
  } catch (e) {
    console.log(e);
  }
}

const { fileUrl } = useFiles()

async function uitloggen() {
  try {

    logout();
    await cartStore.onLogout();
    router.push('/');


    Swal.fire({
      icon: 'success',
      title: 'Succesvol uitgelogd!',
      position: 'top-end',
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  } catch (e) {
    Swal.fire({
      icon: 'error',
      title: 'Er is iets fout gegaan',
      position: 'top-end',
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }
}

const navigatieData = await getItems({
  collection: "header"
});
</script>

<style scoped>
a.router-link-exact-active{
  @apply text-primary-500 font-semibold
}
.menu-item a{
  @apply border-l border-primary-500;
}
.menu-item:first-child a {
  @apply border-none
}
.menu-item-dropdown{
  @apply border-l border-primary-500;
}
</style>