<template>
  <div>
    <div class="mx-auto md:w-8/12">
      <div class="grid md:grid-cols-3 mx-5 md:mx-0">
        <div class="flex flex-col leading-7 text-sm mb-8 text-center md:text-left md:mt-8">
          <h4 class="font-bold text-lg">Sitemap</h4>
          <span v-for="(nav, i) in footerData?.footer_navigatie" :key="i">
            <NuxtLink :to="nav.url" class="hover:text-primary-500 auto-transit-3">{{ nav.naam }}</NuxtLink>
          </span>
        </div>
        <div class="grid justify-items-center mb-8">
          <NuxtImg style="max-height: 92px;" :src="fileUrl(footerData?.footer_afbeelding_1)" alt="assortiment" class="mb-5 mt-2" data-not-lazy/>
          <div class="grid mb-5">
            <HerbruikbaarUnderline class="bg-black"/>
          </div>
          <div class="text-center">
            <NuxtLink to="/assortiment">Bekijk ons assortiment</NuxtLink>
          </div>
        </div>
        <div class="grid justify-items-end mb-8">
          <div class="inline-block mx-auto">
            <NuxtImg style="max-height: 92px;" :src="fileUrl(footerData?.footer_afbeelding_2)" alt="kistjes" class="mb-5 mt-2" data-not-lazy/>
            <div class="grid mb-5">
              <HerbruikbaarUnderline class="bg-black"/>
            </div>
            <div class="text-center">
              <NuxtLink to="/kistjes">Bekijk onze kistjes</NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-600 w-full h-4/6 py-8 mx-auto">
      <div class="mx-auto grid grid-cols-1 md:grid-cols-4 lg:grid-cols-10 md:w-8/12 text-xs text-white">

        <div class="col-span-1 md:col-span-2 lg:col-span-3 text-center mt-2 sm:text-center md:mt-auto md:mb-auto">
          <p>©{{new Date().getFullYear()}} <a class="hover:text-webscoped auto-transit" href="https://webscoped.nl" target="_blank">Webscoped</a> - Alle rechten voorbehouden</p>
        </div>
        <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
          <NuxtLink to="/algemenevoorwaarden"><p>Algemene voorwaarden</p></NuxtLink>
        </div>
        <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
          <NuxtLink to="/algemenevoorwaarden"><p>Privacy voorwaarden</p></NuxtLink>
        </div>
        <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
          <a href="mailto:info@versvandevelde.nl">info@versvandevelde.nl</a>
        </div>
        <div class="col-span-1 md:col-span-4 lg:col-span-1 text-center mt-4 md:mt-auto md:mb-auto flex justify-center">
          <a href="https://www.facebook.com/vers.vandevelde/">
            <div class="rounded-full w-9 h-9 bg-white text-primary-600 grid">
              <font-awesome-icon class="text-xl place-self-center" :icon="['fab', 'facebook-f']"/>
            </div>
          </a>
          <a href="https://www.instagram.com/vers.vandevelde/">
            <div class="rounded-full w-9 h-9 bg-white text-primary-600 grid ml-2">
              <font-awesome-icon class="text-xl place-self-center" :icon="['fab', 'instagram']"/>
            </div>
          </a>
        </div>
      </div>
    </div>
    <a class="z-50 btnzoom border-2 rounded-full bg-whatsapp-500 fixed bottom-0 left-0 mb-7 ml-6 w-16 h-16 grid justify-items-center items-center text-white text-3xl" href="https://wa.me/31618295599" target="_blank" aria-label="Neem contact op via whatsapp" rel="noopener" v-if="quantity == 0 || router.currentRoute.value.fullPath == '/boodschappenlijst'">
      <font-awesome-icon class="w-16 h-16 pb-1" :icon="['fab', 'whatsapp']"/>
    </a>
    <div v-if="router.currentRoute.value.fullPath != '/boodschappenlijst'" class="cursor-pointer">
      <div class="z-50 btnzoom border-2 rounded-full bg-primary-500 fixed bottom-0 left-0 mb-7 ml-6 w-16 h-16 grid justify-items-center items-center text-white text-3xl" @click="openSidemenu" aria-label="Boodschappenlijst openen" rel="noopener" v-if="quantity > 0">
        <div>
        <font-awesome-icon :icon="['fas', 'shopping-basket']"/>
        <span class="flex">
          <span class="text-sm text-center absolute rounded-full h-8 w-8 leading-8 bg-primary-100 text-primary-500 -top-2 -right-2" :class="{winkelwagen : winkelmandanimatie}">{{quantity}}</span>
        </span>
        </div>
      </div>
    </div>
    <AlgemeenSidemenu/>
  </div>

</template>
<script setup>
// import {useShoppingCart} from "~/store/useShoppingcart";

import { useCart } from "~/store/useCart";
import { storeToRefs } from "pinia";

const cartStore = useCart();
const { cart, quantity } = storeToRefs(cartStore);

const winkelmandanimatie = ref(false)
watch(() => quantity.value, (newVal) => {
  if(newVal > 0){
    winkelmandanimatie.value = true;
    setTimeout(() => {
      winkelmandanimatie.value = false;
    }, 500);
  }
})


const { getItems } = useDirectusItems();
const router = useRouter();
const { $listen, $event } = useNuxtApp()

const openSidemenu = () => {
  $event('sidemenu')
}

const footerData = await getItems({
  collection: "footer"
});
const {fileUrl} = useFiles();

</script>
<style scoped>
.winkelwagen{
  -webkit-animation: Animationgradient 500ms cubic-bezier(0, 0.53, 0.56, 1.15);
  -moz-animation: Animationgradient 500ms cubic-bezier(0, 0.53, 0.56, 1.15);
  animation: Animationgradient 500ms cubic-bezier(0, 0.53, 0.56, 1.15);
}

@-webkit-keyframes Animationgradient {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes Animationgradient {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes Animationgradient {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>