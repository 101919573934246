<template>
  <div v-if="popupData.popup_trigger == true || popupopen == true">
    <div class="absolute top-0 left-0 w-screen h-screen bg-gray-600 bg-opacity-50 z-200" @click="$emit('close')">
      <div class="flex h-full">
        <div class="p-10 m-auto text-center bg-white border-2 rounded-sm border-primary-500 md:w-3/4 xl:w-1/2" @click.stop>
          <h2 class="pb-3 mb-3 text-xl border-b-2 border-primary-300" v-html="popupData.popup_hoofdtekst"></h2>
          <div class="text-lg" v-html='popupData.popup_tekst_1'></div>
          <div class="flex justify-center w-full">
            <button
                class="px-5 py-2 mt-10 text-white transition duration-300 ease-in-out bg-primary-500 hover:bg-primary-600"
                @click="$emit('close')">
              Sluit melding
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const popupopen = ref(false)
const zondag = ref(false)
const popup_trigger = ref(false)
const { getItems } = useDirectusItems();

const popupData = await getItems({
  collection: "popup_gesloten"
});

onMounted(() =>  {
  if (process.client) {
    zondag.value = new Date().getDay() == 0;

    const nu = new Date();
    const local = localStorage.getItem("popupopen")
    // console.log(typeof local)
    if (local == undefined || nu.getTime() > new Date(parseInt(local)).getTime()) {
      popupopen.value = true
      nu.setHours(nu.getHours() + 1)
      localStorage.setItem("popupopen", nu.getTime())
    }
  }
})

const getCurrentDay = () => {
  return new Date().getDay();
}
</script>

<style scoped>
.z-200 {
  z-index: 200;
}
</style>
