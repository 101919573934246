<template>
  <nav class="bg-primary-600 relative flex flex-wrap items-center justify-between py-3 z-[101]" @click="$emit('interaction')">
    <div class="container mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start pl-5 sm:px-5">
        <NuxtLink class="leading-relaxed inline-block sm:mr-4 py-2 pr-5 whitespace-nowrap w-3/4" to="/">
          <NuxtImg :src="fileUrl(navigatieData.logo)" alt="Vers van de Velde" class="sm:w-full"/>
        </NuxtLink>
        <div id="nav-icon4" class="z-50 relative flex self-center justify-self-end mr-0 my-0" @click="isOpen = !isOpen" v-on:click="toggleNavbar()">
          <button type="button" class="block focus:outline-none float-right text-primary-500 focus:text-primary-500" :class="{'text-primary-500 focus:text-primary-500':reverse,'text-white focus:text-primary-500':!reverse,'open' :isOpen, 'close':!isOpen}" aria-label="Menu knop">
            <div>
              <span class="bg-white"></span>
              <span class="bg-white"></span>
              <span class="bg-white"></span>
            </div>
          </button>
        </div>
      </div>
      <div :class="{'': !showMenu, 'translate-x-0': showMenu}" class="transition-all duration-300 overflow-auto fixed left-0 top-0 z-50 object-left-top w-full h-screen border-gray-200 bg-white -translate-x-full" @click.stop>
        <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start pl-5 sm:px-5 border-b-2 border-primary-200 py-3">
          <NuxtLink class="leading-relaxed inline-block sm:mr-4 py-2 pr-5 whitespace-nowrap w-3/4" to="/">
            <NuxtImg :src="fileUrl(navigatieData.logo)" alt="Vers van de Velde" class="sm:w-full"/>
          </NuxtLink>
          <div id="nav-icon4" class="z-50 relative flex self-center justify-self-end mr-0 my-0" @click="isOpen = !isOpen" v-on:click="toggleNavbar()">
            <button type="button" class="block focus:outline-none float-right" :class="{'text-primary-500 focus:text-primary-500':reverse,'text-white focus:text-primary-500':!reverse,'open' :isOpen, 'close':!isOpen}" aria-label="Menu knop">
              <div>
                <span class="bg-primary-500"></span>
                <span class="bg-primary-500"></span>
                <span class="bg-primary-500"></span>
              </div>
            </button>
          </div>
        </div>
        <div class="w-full my-2" v-if="showCategories">
          <ul class="text-lg">
<!--            <li class="headtext categorie" v-if="hoofdcat != undefined"  :class="{'focus:text-primary-500 text-primary-500':reverse,'text-white':!reverse}">-->
<!--              <NuxtLink :to="'/categorie/' + hoofdcat" class="hover:text-primary-100 auto-transit flex">-->
<!--                <div class="h-14 w-14"></div>-->
<!--                Terug naar {{hoofdcat}}-->
<!--              </NuxtLink>-->
<!--            </li>-->
<!--            <li class="headtext categorie" v-else-if="categorie != undefined"  :class="{'focus:text-primary-500 text-primary-500':reverse,'text-white':!reverse}">-->
<!--              <NuxtLink to="/assortiment" class="hover:text-primary-100 auto-transit flex">-->
<!--                Terug naar ons assortiment-->
<!--              </NuxtLink>-->
<!--            </li>-->
            <template v-for="(cat,naam) in categories" :key="naam">
              <li class="text-primary-600 futura-text categorie">
                <NuxtLink :to="'/categorie/' + cat.slug" class="flex hover:text-primary-100 auto-transit">
                  <NuxtImg alt="" data-not-lazy :src="fileUrl(cat.icoon)" class="h-12 w-12 mt-0.5 mr-2" :alt="cat.naam"/>
                  {{ cat.naam }}
                </NuxtLink>
              </li>
            </template>
          </ul>
        </div>
        <div class="lg:flex lg:flex-grow items-center">
          <template v-if="!showCategories">
            <ul class="flex flex-col lg:flex-row list-none ml-auto">
              <li class="nav-item" v-for="(nav,i ) in navigatieData.mobiele_menu" :key="i">
                <NuxtLink class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-head futura-headtext" @click="closeMenu" :to="nav.url">{{ nav.naam }}</NuxtLink>
              </li>
            </ul>
            <div @click="logoutWrapper" v-if="user && user?.tempUser === false" class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-red-500 text-head futura-headtext cursor-pointer">Uitloggen</div>
          </template>
          <div class="grid bottom-0 pt-12 border-t-2 border-primary-200" v-if="!user || user?.tempUser === true">
            <nuxt-link tag="button" to="/inloggen" class="border-4 border-primary-500 px-5 py-2 bg-primary-500 text-white flex justify-self-center futura-headtext w-4/5 h-12 mb-3"><font-awesome-icon :icon="['fas', 'sign-in-alt']" class="place-self-center mr-5" /> aanmelden</nuxt-link>
            <nuxt-link tag="button" to="/registreren" class="border-4 border-primary-500 px-5 py-2 bg-primary-500 text-white flex justify-self-center futura-headtext w-4/5 h-12"><font-awesome-icon :icon="['fas', 'user-plus']" class="place-self-center mr-5" /> account aanmaken</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
const router = useRouter();
const { fileUrl } = useFiles()
const { getItems } = useDirectusItems();
const user = useDirectusUser();

import { useCart } from "~/store/useCart";
const cartStore = useCart();

const { logout } = useDirectusAuth();
const logoutWrapper = async () => {
  await logout()
  await router.push('/')
  await cartStore.onLogout()
}

const isOpen = ref(false)
const showMenu = ref(false)
const search = ref(false)
const reverse = ref(true)
const showCategories = ref(false)
const currentCategorie = ref('')

watch(() => router.currentRoute.value, () => {
  if(router.currentRoute.value.path === '/categorie'){
    showCategories.value = true

    if(router.currentRoute.value.path.split('/').length === 3){
      currentCategorie.value = router.currentRoute.value.path.split('/')[2]
    }
    setCategorieMenu()
  }

  if(router.currentRoute.value.path === '/assortiment'){
    showCategories.value = true
    setCategorieMenu()
  } else {
    showCategories.value = false
  }
})

if(router.currentRoute.value.path === '/categorie'){
  showCategories.value = true

  if(router.currentRoute.value.path.split('/').length === 3){
    currentCategorie.value = router.currentRoute.value.path.split('/')[2]
  }
}

showCategories.value = router.currentRoute.value.path === '/assortiment';

const closeMenu = () => {
  showMenu.value = false
  isOpen.value = false
}
const toggleNavbar = () => {
  showMenu.value = !showMenu.value
}

const navigatieData = await getItems({
  collection: "header"
});


const categories = ref([])
async function setCategorieMenu(){
  categories.value = []
  if(router.currentRoute.value.params.categorie){
    const categoriesList = await getItems({
      collection: "categorieen",
      params: {
        fields: ['bovenliggende_categorieen.*.*'],
        filter: {
          slug: {
            _eq: router.currentRoute.value.params.categorie,
          }
        }
      }
    });

    if(categoriesList && categoriesList[0] && categoriesList[0].bovenliggende_categorieen){
      categoriesList[0].bovenliggende_categorieen.forEach((categorie) => {
        if(categorie.related_categorieen_id){
          categories.value.push(categorie.related_categorieen_id)
        }
      })
    }
  } else {
    categories.value = await getItems({
      collection: "categorieen",
      params: {
        fields: ['*'],
        filter: {
          hoofdcategorie: {
            _eq: true,
          },
        }
      }
    });
  }
}
setCategorieMenu()

</script>

<style scoped>
#nav-icon4 {
  width: 60px;
  height: 26px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4 .open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  /*left: 4px;*/
}
#nav-icon4 .open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
#nav-icon4 .open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  /*left: 4px;*/
}
</style>