<template>
  <div class="fixed bg-black bg-opacity-20 inset-0 h-screen z-50" v-if="showSidebar"/>
  <div :class="{'opacity-0 hidden': !showSidebar, 'opacity-100 flex translate-x-0 w-full': showSidebar}" class="transition-opacity duration-300 overflow-auto fixed left-0 top-0 bottom-0 z-50 h-screen bg-black bg-opacity-5 flex-col" @click.stop="closePopup" style="z-index: 100;"></div>
  <div :class="{'translate-x-full': !showSidebar, 'translate-x-0 w-auto lg:w-1/4': showSidebar}" class="transition-all duration-300 overflow-auto fixed right-0 top-0 bottom-0 z-50 h-screen bg-white border-gray-200 md:border-l-2 flex flex-col" @click.stop style="z-index: 101;">
    <div class="grid grid-cols-5 px-5 py-5 border-b-2 border-gray-200">
      <div class="">
        <div class="icon">
          <svg id="a3a460ff-7c8c-4ff4-8fc8-7679064eeabe" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 50 50">
            <path class="f819c40d-dfaf-4464-b591-d1205fb6a277"
                  d="M38.8,42H11.4a1.9,1.9,0,0,1-1.9-1.5L5.7,24.7a.4.4,0,0,1,.4-.4H44.2c.2,0,.4.2.3.4L40.7,40.5A1.9,1.9,0,0,1,38.8,42Z"/>
            <rect class="f819c40d-dfaf-4464-b591-d1205fb6a288" x="29" y="32.1" width="9.4" height="2.18" rx="0.5"
                  transform="translate(72.2 5.6) rotate(99.7)"/>
            <rect class="f819c40d-dfaf-4464-b591-d1205fb6a288" x="20.5" y="31.6" width="9.3" height="2.18" rx="0.5"
                  transform="translate(57.8 7.5) rotate(90)"/>
            <rect class="f819c40d-dfaf-4464-b591-d1205fb6a288" x="11.9" y="32.1" width="9.4" height="2.18" rx="0.5"
                  transform="translate(46.5 11.2) rotate(80.3)"/>
            <path class="f819c40d-dfaf-4464-b591-d1205fb6a277"
                  d="M47,20H41.8L32.5,8.1a.8.8,0,0,0-1-.1L30,9.2a.8.8,0,0,0-.1,1L37.7,20H12.1l7.8-9.8a.7.7,0,0,0-.2-1L18.3,8a.8.8,0,0,0-1,.2L8,20H3.1A1,1,0,0,0,2,21v.9A1.1,1.1,0,0,0,3.1,23H47a1,1,0,0,0,1-1.1V21A.9.9,0,0,0,47,20Z"/>
          </svg>
        </div>
      </div>
      <div class="flex-1 col-span-3 text-center">
        <p class="text-xl font-bold md:text-base">Boodschappenlijst</p>
      </div>
      <div class="flex justify-end">
        <div class="w-6/12 icon cursor-pointer" @click="closePopup">
          <svg id="e596bc40-e60d-4513-ae3d-4e91be84cd29" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 50 50">
            <rect class="f819c40d-dfaf-4464-b591-d1205fb6a277" x="22.9" y="4.4" width="4.6" height="40.69" rx="2.3"
                  transform="translate(24.9 -10.6) rotate(45)"/>
            <rect class="f819c40d-dfaf-4464-b591-d1205fb6a277" x="23.2" y="4.6" width="4.6" height="40.69" rx="2.3"
                  transform="translate(61.2 24.6) rotate(135)"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="flex-1 overflow-auto" v-if="quantity > 0">
      <div v-for="(prod, prodid) of cart.producten" :key="prodid">
        <div class="grid grid-cols-5 py-2 border-b-2 border-gray-100 product">
          <div class="col-span-1 p-2">
            <img class="w-full product-image" :alt="prod.producten_id.naam" :src="editedFileUrl(prod.producten_id.afbeelding, 20, 150, 100, 'contain')"/>
          </div>
          <div class="col-span-3 p-2">
            <p>
              {{ prod.producten_id.naam }}(per {{ prod.producten_id.eenheid }})
            </p>
            <div class="grid grid-cols-2">
              <div class="col-span-1">
                <p>{{ setCurrency((prod.totaalprijs_product)) }}</p>
              </div>
              <div class="col-span-1">
                <div class="w-32">
                  <HerbruikbaarAantalVeld :product="prod"></HerbruikbaarAantalVeld>
                </div>
              </div>
            </div>
          </div>
          <div class="relative flex justify-end col-span-1 p-2 pointer-events-none">
            <div class="absolute w-4 cursor-pointer icon-small right-5 pointer-events-auto"
                 @click="deleteFromCart(prod.producten_id.id)">
              <svg id="e596bc40-e60d-4513-ae3d-4e91be84cd29" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 50 50">
                <rect class="f819c40d-dfaf-4464-b591-d1205fb6a277" x="22.9" y="4.4" width="4.6" height="40.69" rx="2.3"
                      transform="translate(24.9 -10.6) rotate(45)"/>
                <rect class="f819c40d-dfaf-4464-b591-d1205fb6a277" x="23.2" y="4.6" width="4.6" height="40.69" rx="2.3"
                      transform="translate(61.2 24.6) rotate(135)"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-1 px-5 py-5">
        <div class="text-center">
          <p class="text-lg font-bold">Geen producten in de boodschappenlijst</p>
        </div>
      </div>
    </div>
    <div class="relative bottom-0 left-0 right-0 bg-white border-t-2 border-gray-200 menu-footer">
      <div class="grid grid-cols-2 px-5 py-5 total">
        <div class="total-text">
          <p class="text-lg font-bold">Totaal</p>
        </div>
        <div class="text-right total-price">
          <p class="text-lg font-bold">{{ setCurrency(totalOfCart) }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1 px-5 py-5 border-t-2 border-gray-200 checkout">
        <div class="w-full h-full">
          <nuxt-link to="/boodschappenlijst" @click="showSidebar = false"
                     class="w-full h-full py-2 text-center text-white bg-primary-600 block">Betalen
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCart} from "~/store/useCart";
import {storeToRefs} from "pinia";

const cartStore = useCart();
const {cart, quantity, totalOfCart} = storeToRefs(cartStore);

const {$listen, $event} = useNuxtApp()
const {fileUrl, editedFileUrl} = useFiles();
const showSidebar = ref(false);
const {setCurrency} = setToCurrency();

$listen('sidemenu', () => {
  showSidebar.value = true
  // getProducts()
})
const closePopup = () => {
  showSidebar.value = false
}

// const cartItems = ref([]);
const producten = ref([])
// const totalOfCart = ref(0)

// watch(cart.value, () => {
//   console.log('cart changed')
//   // getProducts()
// })
const{$bus} = useNuxtApp()
// $bus.$on('cartUpdated', () => {
//   getProducts()
// })

// async function getProducts() {
//   // const cart = await shoppingcartData.getCart()
//   cartItems.value = cart.value.producten
// }

async function deleteFromCart(id) {
  await cartStore.removeFromCart(id)
  // await shoppingcartData.removeFromCart(id)
  // await getProducts()
  // $event('cart:refreshAmount')
}
</script>

<style>
.icon {
  max-width: 25px;
}

.product-image {
  max-width: 80px;
}

.f819c40d-dfaf-4464-b591-d1205fb6a288 {
  fill: #ffffff;
}

.f819c40d-dfaf-4464-b591-d1205fb6a277 {
  fill: #2b5336;
}

.totheleft {
  -webkit-animation: cubic-bezier(0, 0.5, 0.75, 1);
  -webkit-animation-name: run;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
}

@-webkit-keyframes run {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
